import { Component } from '@angular/core'
import { NgOptimizedImage } from '@angular/common'

@Component({
    selector: 'app-audits-logements-portlet-body',
    templateUrl: './rendez-vous-locataires-portlet-body.component.html',
    styleUrls: ['./rendez-vous-locataires-portlet-body.component.scss'],
    standalone: true,
    imports: [NgOptimizedImage],
})
export class RendezVousLocatairesPortletBodyComponent {
    protected readonly qrCodeUrl = 'assets/images/home/rdv-locataires-body.png'
    protected readonly imgHeaderUrl = 'assets/images/home/rdv-locataires-header.png'
}
