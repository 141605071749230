<ol class="breadcrumb fs-5">
    <li class="breadcrumb-item">
        <a *ngIf="!isIris" [routerLink]="'/'" class="breadcrumb-title"><i class="bi bi-chevron-left"></i> Portail Chantier</a>
        <a *ngIf="isIris" [routerLink]="'/'" class="breadcrumb-title"><i class="bi bi-chevron-left"></i> Portail IRIS</a>
    </li>
    <ng-container *ngFor="let path of paths">
        <li *ngIf="!path.lien" aria-current="page" class="breadcrumb-item active">{{ path.label }}</li>
        <li *ngIf="path.lien" aria-current="page" class="breadcrumb-item active">
            <a [routerLink]="path.lien">{{ path.label }}</a>
        </li>
    </ng-container>
</ol>
