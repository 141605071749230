import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

@Component({
    selector: 'app-breadcrumb',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
    @Input({ required: true })
    paths: BreadcrumbPath[]

    @Input ({required:true})
    isIris: boolean
}

export interface BreadcrumbPath {
    label: string
    lien?: string
}
