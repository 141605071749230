import { Component, inject, Inject, Input } from '@angular/core'
import { LocmatDto } from '../../../data-access/http/dto/locmat-dto'

import { newDateFromString } from '../../../../shared/utils/date.utils'
import { AsyncPipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common'
import { PieceJointeHttpService } from '../../../../shared/data-access/http/piece-jointe-http.service'
import { forkJoin, Observable, of } from 'rxjs'
import { PhotoLocmatDto } from '../../../data-access/http/dto/photo-locmat.dto'

@Component({
    selector: 'app-locmat-portlet-body',
    templateUrl: './locmat-portlet-body.component.html',
    styleUrls: ['./locmat-portlet-body.component.scss'],
    standalone: true,
    imports: [NgIf, NgOptimizedImage, AsyncPipe, NgForOf],
})
export class LocmatPortletBodyComponent {
    @Input()
    protected data: LocmatDto

    protected photos$: Observable<PhotoLocmatDto[]>
    private readonly pieceJointeHttpService = inject(PieceJointeHttpService)
    constructor(@Inject('DATA') dataInjected: string) {
        this.data = this.data || JSON.parse(dataInjected)
        this.data.dateDernierPointage = newDateFromString(this.data.dateDernierPointage)

        const { pieceJointePhotoIdImage1, pieceJointePhotoIdImage2, pieceJointePhotoIdImage3 } = this.data
        const loadLocmatPhoto = (locmatPhotoId: number): Observable<PhotoLocmatDto> =>
            locmatPhotoId ? this.pieceJointeHttpService.getPhotoLocmatByLambdaId(locmatPhotoId) : of(null)
        this.photos$ = forkJoin([
            loadLocmatPhoto(pieceJointePhotoIdImage1),
            loadLocmatPhoto(pieceJointePhotoIdImage2),
            loadLocmatPhoto(pieceJointePhotoIdImage3),
        ])
    }
}
