<div class="user-pp" (click)="toggleMenu()" role="button">{{ user.getInitiales() }}</div>


<ng-container *ngIf="isMobile$ | async">
    <div [class.modal-backdrop]="menuOpen" (click)="toggleMenu()"></div>
    <div class="user-menu-mobile " [class.show]="menuOpen">
        <div class="fit-content" [title]="user?.getDisplayName()">
            <p class="fw-semibold text-truncate mb-0 ps-0">{{ user?.getDisplayName() }}</p>
        </div>
        <div class="fit-content">
            <div (click)="logOut()" class="user-disconnect mb-0 ps-0">Déconnexion</div>
        </div>
        <div *ngIf="user?.superAdmin" class="fit-content">
            <a class="user-disconnect pt-1" routerLink="/admin">Administration</a>
        </div>
    </div>
</ng-container>


<div class="d-flex flex-column fit-content ps-4 d-none d-md-flex">
    <div class="fit-content" [title]="user?.getDisplayName()">
        <p class="fw-semibold text-truncate mb-0 ps-0">{{ user?.getDisplayName() }}</p>
    </div>
    <div class="fit-content">
        <div (click)="logOut()" class="user-disconnect mb-0 ps-0">Déconnexion</div>
    </div>
    <div *ngIf="user?.superAdmin" class="fit-content">
        <a class="user-disconnect pt-1" routerLink="/admin">Administration</a>
    </div>
</div>
