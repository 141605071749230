import { Component, inject, Inject } from '@angular/core'
import { DocumentGedDto } from '../../../../shared/data-access/http/dto/document-ged.dto'
import { DatePipe, DOCUMENT, JsonPipe, NgForOf } from '@angular/common'
import { DocumentGed } from '../../../../shared/models/document-ged'
import { parseCustomDate } from '../../../../shared/utils/date.utils'
import { PieceJointeHttpService } from '../../../../shared/data-access/http/piece-jointe-http.service'
import { switchMap, tap } from 'rxjs'
import { PieceJointeDto } from '../../../../shared/data-access/http/dto/piece-jointe.dto'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import {
    AdminDocumentationModalComponent,
    UpdateDocumentIsActiveEvent,
    UpdateDocumentIsFavoriEvent,
} from './admin-documentation-modal/admin-documentation-modal.component'
import { IServerSideGetRowsParams } from 'ag-grid-community'
import { DocumentGedHttpService } from '../../../../shared/data-access/http/document-ged-http.service'
import { PageResponse } from '../../../../shared/data-access/http/dto/page-dto'
import { ChantierDomainService } from '../../../../shared/domain/chantier.domain'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
    selector: 'app-documentation-chantier-portlet-body',
    standalone: true,
    imports: [JsonPipe, NgForOf, DatePipe],
    templateUrl: './documentation-chantier-portlet-body.component.html',
    styleUrl: './documentation-chantier-portlet-body.component.scss',
})
export class DocumentationChantierPortletBodyComponent {
    data: DocumentGed[]
    private readonly pieceJointeHttpService = inject(PieceJointeHttpService)
    private readonly documentGedHttpService = inject(DocumentGedHttpService)
    private readonly chantierDomainService = inject(ChantierDomainService)
    private readonly modalService = inject(NgbModal)
    private readonly document = inject(DOCUMENT)
    constructor(@Inject('DATA') private dataInjected: string) {
        const dtos: DocumentGedDto[] = JSON.parse(this.dataInjected)
        this.data = dtos.map((dto: DocumentGedDto) => ({
            certificatRealisation_fileName: dto.certificatRealisation_fileName,
            certificatRealisation_id: dto.certificatRealisation_id,
            chantier_code: dto.chantier_code,
            chantier_nom: dto.chantier_nom,
            certificatRealisation_lambdaId: dto.certificatRealisation_lambdaId,
            createdBy_nom: dto.createdBy_nom,
            createdBy_prenom: dto.createdBy_prenom,
            document_fileName: dto.document_fileName,
            document_id: dto.document_id,
            document_lambdaId: dto.document_lambdaId,
            documentSigne_fileName: dto.documentSigne_fileName,
            documentSigne_id: dto.documentSigne_id,
            documentSigne_lambdaId: dto.documentSigne_lambdaId,
            enveloppeId: dto.enveloppeId,
            typeDocumentGed_nom: dto.typeDocumentGed_nom,

            createdDate: parseCustomDate(dto.createdDate),
            dateSignature: parseCustomDate(dto.dateSignature),
            collaborateur: dto.collaborateur,
        }))
    }

    downloadDocument(document: DocumentGed): void {
        this.pieceJointeHttpService
            .getPhotoById(document.document_id)
            .pipe(tap((pj: PieceJointeDto) => this.document.defaultView.open(pj.lambdaUrl)))
            .subscribe()
    }

    openDocumentsModale(): void {
        const modalRef = this.modalService.open(AdminDocumentationModalComponent, { fullscreen: true })
        const modalComponent: AdminDocumentationModalComponent = modalRef.componentInstance
        modalComponent.chantierCode = this.chantierDomainService.selectedChantierCode()
        modalComponent.documentsDataSource = {
            getRows: (params: IServerSideGetRowsParams): void => {
                const { sortModel, startRow, filterModel } = params.request
                const pageSize = 5
                const pageNumber = startRow / pageSize
                const selectedChantierCode = this.chantierDomainService.selectedChantierCode()
                this.documentGedHttpService
                    .getDocumentsChantier(selectedChantierCode, pageSize, pageNumber, sortModel, filterModel)
                    .subscribe((documentsPage: PageResponse<DocumentGedDto>) => {
                        params.success({ rowData: documentsPage.content, rowCount: documentsPage.totalElements })
                    })
            },
        }
        modalComponent.documentClicked
            .pipe(
                takeUntilDestroyed(modalComponent.destroyRef),
                switchMap((document) =>
                    this.pieceJointeHttpService
                        .getPhotoById(document.document_id)
                        .pipe(tap(({ lambdaUrl }) => this.document.defaultView.open(lambdaUrl))),
                ),
            )
            .subscribe()
        modalComponent.updateDocumentIsActive
            .pipe(
                takeUntilDestroyed(modalComponent.destroyRef),
                switchMap((event: UpdateDocumentIsActiveEvent) =>
                    this.documentGedHttpService.updateDocumentIsActive(event.documentId, event.active),
                ),
            )
            .subscribe()
        modalComponent.updateDocumentIsFavori
            .pipe(
                takeUntilDestroyed(modalComponent.destroyRef),
                tap((event: UpdateDocumentIsFavoriEvent) => {
                    this.documentGedHttpService
                        .updateDocumentIsFavori(event.documentId, event.favori)
                        .pipe(
                            tap({
                                error: () => {
                                    modalComponent.grid.refreshServerSide()
                                },
                            }),
                        )
                        .subscribe()
                }),
            )
            .subscribe()
    }
}
