export abstract class HostEnvironment {
    abstract id: HostEnvironmentEnum
    abstract url: string
    load():void {
        this.setTitle()
        this.setFavicon()
    }
    abstract setFavicon(): void
    abstract setTitle(): void
}
export enum HostEnvironmentEnum {
    PortailChantier,
    PortailIris,
}
