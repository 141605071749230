<div class="container ps-0">
    <div class="d-flex justify-content-between align-items-center py-4">

        <app-logo-display *ngIf="currentLogo"  [currentLogo]="currentLogo" ></app-logo-display>

        <div *ngIf="!shouldDisplayBreadcrumbInHeader" class="mt-3 w-100 d-none d-lg-block">
            <div class="d-flex align-items-center justify-content-center ps-lg-5 ps-sm-2 pb-3">
                <div *ngIf="selectedChantier">
                    <div #dropdownChantier="ngbDropdown" class="d-inline-block" ngbDropdown>
                        <button
                            class="btn btn-outline-primary chantier-selector text-start"
                            id="chantierDropdown"
                            ngbDropdownToggle
                            type="button"
                        >
                            <div class="d-inline-block text-truncate align-text-bottom" style="max-width: 18rem">
                                {{ selectedChantier?.code + " - " + selectedChantier?.nom }}
                            </div>
                        </button>
                        <div
                            aria-labelledby="chantierDropdown"
                            class="overflow-auto"
                            ngbDropdownMenu
                            style="max-height: 20rem"
                        >
                            <button
                                (click)="onSelectChantier(chantier.code)"
                                *ngFor="let chantier of selectableChantiers"
                                class="chantier-button"
                                ngbDropdownItem
                            >
                                <div class="d-inline-block text-truncate">{{ chantier.code }} - {{ chantier.nom }}</div>
                            </button>
                            <button
                                (click)="dropdownChantier.close(); openSelectOtherChantierModal()"
                                class="other-chantier-button"
                            >
                                <div class="d-inline-block">Accéder à un autre chantier</div>
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="shouldDisplayAccesChantierButton">
                    <div class="d-inline-block">
                        <button
                            (click)="openSelectOtherChantierModal()"
                            class="btn btn-primary chantier-access-button"
                            type="button"
                        >
                            Accéder à un chantier
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-grow-1 w-100"></div>

        <div class="d-flex right-section align-items-center">
            <app-contact-widget role="button" [supportEmail]="supportEmail" (click)="onContactSupport()" />
            <app-user-widget [user]="user" (logout)="logOut()" class="pe-0" />
        </div>
    </div>
</div>

<div *ngIf="!shouldDisplayBreadcrumbInHeader" class="mt-3 w-100 d-lg-none">
    <div class="d-flex flex-column align-items-center justify-content-center ps-sm-2 pb-4">
        <div *ngIf="selectedChantier">
            <div #dropdownChantier="ngbDropdown" class="d-inline-block" ngbDropdown>
                <button
                    class="btn btn-outline-primary chantier-selector text-start"
                    id="chantierDropdownMobile"
                    ngbDropdownToggle
                    type="button"

                >
                    <div class="d-inline-block text-truncate align-text-bottom" style="max-width: 18rem">
                        {{ selectedChantier?.code + " - " + selectedChantier?.nom }}
                    </div>
                </button>
                <div
                    aria-labelledby="chantierDropdownMobile"
                    class="overflow-auto"
                    ngbDropdownMenu
                    style="max-height: 20rem"

                >
                    <button
                        (click)="onSelectChantier(chantier.code)"
                        *ngFor="let chantier of selectableChantiers"
                        class="chantier-button"
                        ngbDropdownItem

                    >
                        <div class="d-inline-block text-truncate">{{ chantier.code }} - {{ chantier.nom }}</div>
                    </button>
                    <button
                        (click)="dropdownChantier.close(); openSelectOtherChantierModal()"
                        class="other-chantier-button"
                    >
                        <div class="d-inline-block">Accéder à un autre chantier</div>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="shouldDisplayAccesChantierButton">
            <div class="d-inline-block">
                <button
                    (click)="openSelectOtherChantierModal()"
                    class="btn btn-primary chantier-access-button"
                    type="button"
                >
                    Accéder à un chantier
                </button>
            </div>
        </div>
    </div>
</div>

<nav
    [hidden]="!shouldDisplayBreadcrumbInHeader"
    aria-label="breadcrumb"
    class="navbar navbar-chantier navbar-expand-lg sticky-top bg-white shadow-sm"
>
    <ng-container *ngFor="let data of breadcrumbDatas?.paths">
        <app-breadcrumb *ngIf="data.isActive" [isIris]="isIris" [paths]="data.path"
                        class="container ps-0 mt-3" />
    </ng-container>
</nav>
