import { FavIcon } from '../../shared/models/enums/favicon.enum'

export class CoreUtils {
    static setFaviconElement(faviconPath: FavIcon):void {
        const faviconLinkElement = document.createElement('link')
        faviconLinkElement.href = faviconPath
        faviconLinkElement.rel = 'icon'
        faviconLinkElement.type = 'image/x-icon'
        document.head.appendChild(faviconLinkElement)
    }
}
