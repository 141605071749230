<div class="card-body border-top p-5">
    <div class="modal-header">
        <h4 class="modal-title">Espace documentation chantier {{ chantierCode }}</h4>
        <button (click)="dismissModal()" aria-label="Close" class="btn-close" type="button"></button>
    </div>
    <div>
        <ag-grid-angular
            #grid
            class="ag-theme-quartz"
            [serverSideDatasource]="documentsDataSource"
            [rowModelType]="'serverSide'"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColumnDef"
            [gridOptions]="gridOptions"
            [pagination]="true"
            [paginationPageSizeSelector]="paginationPageSizeSelector"
            [paginationPageSize]="paginationPageSize"
            [maxBlocksInCache]="10"
            [cacheBlockSize]="5"
            [localeText]="localeFr"
            (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>
    </div>
</div>
