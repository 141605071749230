import { Component, EventEmitter, Input, Output } from '@angular/core'
import { User } from '../../../shared/models/user'
import { RouterLink } from '@angular/router'
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgSelectModule } from '@ng-select/ng-select'
import { AsyncPipe, NgFor, NgIf } from '@angular/common'
import { BreadcrumbComponent, BreadcrumbPath } from '../breadcrumb/breadcrumb.component'
import { UserWidgetComponent } from './user-widget/user-widget.component'
import { LogoDisplayComponent } from './logo-display/logo-display.component'
import { ContactWidgetComponent } from './contact-widget/contact-widget.component'
import { ChantierDTO } from '../../../shared/data-access/http/dto/chantier-dto'
import { LogoSetting } from '../../../shared/models/logo-setting'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        NgbDropdown,
        NgbDropdownToggle,
        NgbDropdownMenu,
        NgFor,
        NgbDropdownItem,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        AsyncPipe,
        BreadcrumbComponent,
        UserWidgetComponent,
        LogoDisplayComponent,
        ContactWidgetComponent,

    ],
})
export class HeaderComponent {
    @Input({ required: true })
    user: User
    @Input({ required: true })
    selectedChantier: ChantierDTO
    @Input({ required: true })
    selectableChantiers: ChantierDTO[]
    @Input({ required: true })
    isIris: boolean
    @Input({ required: true })
    supportEmail: string
    @Input({ required: true })
    shouldDisplayBreadcrumbInHeader: boolean
    @Input({ required: true })
    breadcrumbDatas: BreadcrumbDatas
    @Input({ required: true })
    shouldDisplayAccesChantierButton: boolean

    @Input({required : true})
    currentLogo : LogoSetting

    @Output()
    openSelectChantierModal = new EventEmitter<void>()
    @Output()
    logout = new EventEmitter<void>()
    @Output()
    selectChantier = new EventEmitter<string>()
    @Output()
    contactSupport = new EventEmitter<void>()

    onSelectChantier(chantierCode: string): void {
        this.selectChantier.emit(chantierCode)
    }

    openSelectOtherChantierModal(): void {
        this.openSelectChantierModal.emit()
    }

    logOut(): void {
        this.logout.emit()
    }

    onContactSupport(): void {
        this.contactSupport.emit()
    }
}

export interface BreadcrumbDatas {
    paths: BreadcrumbData[]
}

interface BreadcrumbData {
    isActive: boolean
    path: BreadcrumbPath[]
}
