import { inject, Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { FavIcon } from '../../../shared/models/enums/favicon.enum'
import { HostEnvironment, HostEnvironmentEnum } from '../../../shared/models/host-environment'
import { CoreUtils } from '../../utils/core.utils'

@Injectable({ providedIn: 'root' })
export class PortailChantierHostEnvironmentService extends HostEnvironment {
    id = HostEnvironmentEnum.PortailChantier
    url = 'portailchantier'

    private title = inject(Title)
    setFavicon(): void {
        CoreUtils.setFaviconElement(FavIcon.ICONE_PORTAIL_CHANTIER)
    }

    setTitle(): void {
        this.title.setTitle('Portail Chantier')
    }
}
