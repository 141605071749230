import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core'
import {
    CellClickedEvent,
    ColDef,
    GridApi,
    GridOptions,
    GridReadyEvent,
    ICellRendererParams,
    IServerSideDatasource,
} from 'ag-grid-community'
import { AgGridAngular } from 'ag-grid-angular'
import { localeFr } from '../../../../../../assets/localization/ag-grid/ag-grid-locale-fr'
import { DocumentGedDto } from '../../../../../shared/data-access/http/dto/document-ged.dto'
import { formatDate } from '@angular/common'
import 'ag-grid-enterprise'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
    selector: 'app-admin-documentation-modal',
    standalone: true,
    imports: [AgGridAngular],
    templateUrl: './admin-documentation-modal.component.html',
    styleUrl: './admin-documentation-modal.component.scss',
})
export class AdminDocumentationModalComponent {
    @Input()
    documentsDataSource: IServerSideDatasource
    @Input()
    chantierCode: string
    grid: GridApi
    @Output()
    readonly documentClicked = new EventEmitter<DocumentGedDto>()
    @Output()
    readonly updateDocumentIsActive = new EventEmitter<UpdateDocumentIsActiveEvent>()
    @Output()
    readonly updateDocumentIsFavori = new EventEmitter<UpdateDocumentIsFavoriEvent>()
    destroyRef = inject(DestroyRef)

    protected readonly localeFr = localeFr
    protected readonly paginationPageSizeSelector = [5, 10, 20]
    protected readonly paginationPageSize = 5
    private readonly modalService = inject(NgbModal)

    columnDefs: ColDef[] = [
        {
            headerName: 'Type de document',
            field: 'typeDocumentGed_nom',
            filter: 'agTextColumnFilter',
            filterParams: {
                maxNumConditions: 1,
                filterOptions: ['contains', 'equals', 'notContains', 'notEqual'],
                caseSensitive: false,
            },

            flex: 1,
            cellStyle: { textAlign: 'left', cursor: 'pointer' },
            onCellClicked: (event: CellClickedEvent) => this.onRowClicked(event),
        },
        {
            headerName: 'Nom du document',
            field: 'document_fileName',
            filter: 'agTextColumnFilter',
            filterParams: {
                maxNumConditions: 1,
                filterOptions: ['contains', 'equals', 'notContains', 'notEqual'],
                caseSensitive: false,
            },

            flex: 1,
            cellStyle: { textAlign: 'left', cursor: 'pointer' },
            onCellClicked: (event: CellClickedEvent) => this.onRowClicked(event),
        },
        {
            headerName: 'Collaborateur',
            field: 'collaborateur',
            filter: 'agTextColumnFilter',
            filterParams: {
                maxNumConditions: 1,
                filterOptions: ['contains', 'equals', 'notContains', 'notEqual'],
                caseSensitive: false,
            },

            flex: 1,
            cellStyle: { textAlign: 'left', cursor: 'pointer' },
            onCellClicked: (event: CellClickedEvent) => this.onRowClicked(event),
        },
        {
            headerName: 'Contributeur',
            field: 'contributeur',
            filter: 'agTextColumnFilter',
            filterParams: {
                maxNumConditions: 1,
                filterOptions: ['contains', 'equals', 'notContains', 'notEqual'],
                caseSensitive: false,
            },

            flex: 1,
            cellStyle: { textAlign: 'left', cursor: 'pointer' },
            onCellClicked: (event: CellClickedEvent) => this.onRowClicked(event),
        },
        {
            field: 'dateSignature',
            headerName: 'Date de la signature',
            valueGetter: (row) => formatDate(row.data.dateSignature, 'dd/MM/yyyy', 'fr'),
            filter: 'agDateColumnFilter',
            filterParams: {
                maxNumConditions: 1,
                filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange'],
            },
            flex: 1,
            cellStyle: { textAlign: 'left', cursor: 'pointer' },
            onCellClicked: (event: CellClickedEvent) => this.onRowClicked(event),
        },
        {
            field: 'active',
            headerName: 'Actif',
            cellRenderer: (params: ICellRendererParams): HTMLElement => {
                const input = document.createElement('input')
                input.oninput = (ev: Event): void => {
                    this.updateDocumentIsActive.emit({
                        documentId: params.data.id,
                        active: (ev.target as HTMLInputElement).checked,
                    })
                }

                input.type = 'checkbox'
                input.checked = params.data.active
                return input
            },
            flex: 1,
            cellStyle: { textAlign: 'left' },
        },
        {
            field: 'favori',
            headerName: 'Favori',
            cellRenderer: (params: ICellRendererParams): HTMLElement => {
                const input = document.createElement('input')
                input.oninput = (ev: Event): void => {
                    this.updateDocumentIsFavori.emit({
                        documentId: params.data.id,
                        favori: (ev.target as HTMLInputElement).checked,
                    })
                }

                input.type = 'checkbox'
                input.checked = params.data.favori
                return input
            },
            flex: 1,
            cellStyle: { textAlign: 'left' },
            resizable: false,
        },
    ]

    defaultColumnDef: ColDef = {
        suppressHeaderMenuButton: true,
        suppressHeaderContextMenu: true,
    }

    gridOptions: GridOptions = {
        defaultColDef: {
            sortable: true,
            filter: true,
        },
        animateRows: true,
        pagination: true,
    }

    onGridReady(event: GridReadyEvent): void {
        this.grid = event.api
    }

    onRowClicked(event: CellClickedEvent): void {
        this.documentClicked.emit(event.data)
    }

    dismissModal() {
        this.modalService.dismissAll()
    }
}

export interface UpdateDocumentIsActiveEvent {
    documentId: string
    active: boolean
}

export interface UpdateDocumentIsFavoriEvent {
    documentId: string
    favori: boolean
}
