<table class="table table-hover table-borderless">
    <thead class="border-bottom border-primary">
        <tr>
            <th class="portlet-table-cell lg-red">Type de document</th>
            <th class="portlet-table-cell lg-red">Collaborateur</th>
            <th class="portlet-table-cell lg-red">Date</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let document of data" (click)="downloadDocument(document)" role="button">
            <td>{{ document.typeDocumentGed_nom }}</td>
            <td>{{ document.collaborateur }}</td>
            <td>{{ document.createdDate | date: "dd/MM/yyyy" }}</td>
        </tr>
    </tbody>
</table>
<div class="row app-link pt-2">
    <p (click)="openDocumentsModale()" class="text-end pe-0" style="cursor: pointer">
        Accéder aux documents
        <i class="bi bi-box-arrow-up-right"></i>
    </p>
</div>
