<div class="d-flex align-items-center pe-2 ps-2">
    <div class="user-pp px-md-0 px-3">
        <i class="bi bi-envelope"></i>
    </div>
    <div class="d-flex flex-column fit-content ps-4 d-none d-md-flex">
        <div class="row justify-content-end fit-content">
            <div title="Contacter l'équipe support">
                <p class="fw-semibold mb-0 ps-0">Contacter l'équipe support</p>
            </div>
            <div class="fit-content lgRed">{{ supportEmail }}</div>
        </div>
    </div>
</div>
