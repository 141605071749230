import { inject, Injectable } from '@angular/core'
import { PortailIrisHostEnvironmentService } from './host-environments/portail-iris-host-environment.service'
import { PortailChantierHostEnvironmentService } from './host-environments/portail-chantier-host-environment.service'
import { HostEnvironment } from '../../shared/models/host-environment'

@Injectable({ providedIn: 'root' })
export class HostEnvironmentLoader {
    private hostEnvironments: HostEnvironment[] = [
        inject(PortailChantierHostEnvironmentService),
        inject(PortailIrisHostEnvironmentService),
    ]

    loadViaDomainName(hostName: string) :void {
        for (const host of this.hostEnvironments) {
            if (hostName.startsWith(host.url)) {
                host.load()
                return
            }
            this.hostEnvironments[0].load()
        }
    }
}
