import { inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../../../environments/environment'
import { Observable } from 'rxjs'
import { DocumentGedDto } from './dto/document-ged.dto'
import { PageResponse } from './dto/page-dto'
import { FilterModel, SortModelItem } from 'ag-grid-community'
import { toCustomDate } from '../../utils/date.utils'

@Injectable({
    providedIn: 'root',
})
export class DocumentGedHttpService {
    private static readonly endpoint = environment.baseUrl + '/documentged'
    private readonly http = inject(HttpClient)

    private static readonly filterOperators: Record<string, string> = {
        contains: 'cn',
        equals: 'eq',
        notContains: 'nc',
        notEqual: 'ne',
        greaterThanOrEqual: 'ge',
        lessThanOrEqual: 'le',
        greaterThan: 'gt',
        lessThan: 'lt',
        inRange: 'btw',
    }

    getSalarieDocuments(
        matricule: string,
        pageSize?: number,
        pageNumber?: number,
        sortModel?: SortModelItem[],
        filterModel?: FilterModel,
    ): Observable<PageResponse<DocumentGedDto>> {
        let params: HttpParams = new HttpParams()

        if (Number.isInteger(pageSize) && Number.isInteger(pageNumber)) {
            params = params.append('size', pageSize).append('page', pageNumber)
        }
        if (sortModel?.length) {
            params = params.append('sort', `${sortModel[0].colId}:${sortModel[0].sort}`)
        }
        if (filterModel) {
            for (const filter of Object.entries(filterModel)) {
                const [key, value] = filter
                if (value.filterType === 'date') {
                    params = params.append(
                        'search',
                        `${key}:${DocumentGedHttpService.filterOperators[value.type]}:${toCustomDate(value.dateFrom)}${value.dateTo ? '|' + toCustomDate(value.dateTo) : ''}`,
                    )
                } else {
                    params = params.append(
                        'search',
                        `${key}:${DocumentGedHttpService.filterOperators[value.type]}:${value.filter}`,
                    )
                }
            }
        }
        return this.http.get<PageResponse<DocumentGedDto>>(
            DocumentGedHttpService.endpoint + `/find/salarie/${matricule}`,
            { params },
        )
    }

    getInterimDocuments(
        matricule: string,
        pageSize?: number,
        pageNumber?: number,
        sortModel?: SortModelItem[],
        filterModel?: FilterModel,
    ): Observable<PageResponse<DocumentGedDto>> {
        let params: HttpParams = new HttpParams()

        if (Number.isInteger(pageSize) && Number.isInteger(pageNumber)) {
            params = params.append('size', pageSize).append('page', pageNumber)
        }
        if (sortModel?.length) {
            params = params.append('sort', `${sortModel[0].colId}:${sortModel[0].sort}`)
        }
        if (filterModel) {
            for (const filter of Object.entries(filterModel)) {
                const [key, value] = filter
                if (value.filterType === 'date') {
                    params = params.append(
                        'search',
                        `${key}:${DocumentGedHttpService.filterOperators[value.type]}:${toCustomDate(value.dateFrom)}${value.dateTo ? '|' + toCustomDate(value.dateTo) : ''}`,
                    )
                } else {
                    params = params.append(
                        'search',
                        `${key}:${DocumentGedHttpService.filterOperators[value.type]}:${value.filter}`,
                    )
                }
            }
        }
        return this.http.get<PageResponse<DocumentGedDto>>(
            DocumentGedHttpService.endpoint + `/find/interimaire/${matricule}`,
            { params },
        )
    }

    getDocumentsChantier(
        codeChantier: string,
        pageSize?: number,
        pageNumber?: number,
        sortModel?: SortModelItem[],
        filterModel?: FilterModel,
    ): Observable<PageResponse<DocumentGedDto>> {
        let params: HttpParams = new HttpParams()

        if (Number.isInteger(pageSize) && Number.isInteger(pageNumber)) {
            params = params.append('size', pageSize).append('page', pageNumber)
        }
        if (sortModel?.length) {
            params = params.append('sort', `${sortModel[0].colId}:${sortModel[0].sort}`)
        }
        params = params.append('sort', `id:asc`)

        if (filterModel) {
            for (const filter of Object.entries(filterModel)) {
                const [key, value] = filter
                if (value.filterType === 'date') {
                    params = params.append(
                        'search',
                        `${key}:${DocumentGedHttpService.filterOperators[value.type]}:${toCustomDate(value.dateFrom)}${value.dateTo ? '|' + toCustomDate(value.dateTo) : ''}`,
                    )
                } else {
                    params = params.append(
                        'search',
                        `${key}:${DocumentGedHttpService.filterOperators[value.type]}:${value.filter}`,
                    )
                }
            }
        }
        return this.http.get<PageResponse<DocumentGedDto>>(
            DocumentGedHttpService.endpoint + `/find/chantier/${codeChantier}`,
            { params },
        )
    }

    updateDocumentIsActive(documentId: string, active: boolean): Observable<DocumentGedDto> {
        let params = new HttpParams()
        params = params.append('active', active)
        return this.http.put<DocumentGedDto>(DocumentGedHttpService.endpoint + `/setActive/${documentId}`, null, {
            params,
        })
    }

    updateDocumentIsFavori(documentId: string, favori: boolean): Observable<DocumentGedDto> {
        let params = new HttpParams()
        params = params.append('favori', favori)
        return this.http.put<DocumentGedDto>(DocumentGedHttpService.endpoint + `/setFavori/${documentId}`, null, {
            params,
        })
    }
}
