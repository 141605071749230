import { Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { AsyncPipe, NgIf } from '@angular/common'
import { User } from '../../../../shared/models/user'
import { RouterLink } from '@angular/router'
import { Observable } from 'rxjs'
import { MobileService } from '../../../../shared/service/mobile.service'

@Component({
    selector: 'app-user-widget',
    standalone: true,
    imports: [NgIf, RouterLink, AsyncPipe],
    templateUrl: './user-widget.component.html',
    styleUrl: './user-widget.component.scss',
})
export class UserWidgetComponent {
    @Input({ required: true })
    user: User
    @Output()
    logout = new EventEmitter<void>()

    isMobile$: Observable<boolean>


    protected menuOpen = false
    private mobileService: MobileService

    constructor() {
        this.mobileService = inject(MobileService)
        this.isMobile$ = this.mobileService.isMobile$
    }

    toggleMenu(): void {
        this.menuOpen = !this.menuOpen
    }

    logOut(): void {
        this.logout.emit()
    }
}
